import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const VideoTemplate = ({ title, content, video, contentComponent }) => {
  const PageContent = contentComponent || Content;
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <PageContent className="content" content={content} />
              <div className="casi-clinici-video">
                {video.map(v => 
                  <div key={v.id} className="embed-container">
                    <iframe title={`video-${v.id}`} src={`https://www.youtube.com/embed/${v.id}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const VideoPage = ({ data }) => {
  const { markdownRemark: page } = data
  return (
    <Layout>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${page.frontmatter.immagine.childImageSharp.fluid.src})`,
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1 main-title"
          style={{
            color: '#2fbaff',
            padding: '1rem',
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          {page.frontmatter.titolo}
        </h1>
      </div>
      <VideoTemplate
        contentComponent={HTMLContent}
        video={page.frontmatter.video}
        content={page.html}
      />
    </Layout>
  )
}

VideoPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default VideoPage

export const videoPageQuery = graphql`
  query VideoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        titolo
        video {
          id
        }
        immagine {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
